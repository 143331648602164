a{
	color:inherit;

	&:hover,
	&:focus{
		outline: none;
		text-decoration: none;
	}
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.page-header{
	border-bottom: none;

	h1{
		font-family: "open_sansregular";
		font-size: 42px;
		margin-bottom: 10px;
	}
	
	h2{
		font-family: "open_sansregular";
		font-size: 23px;

		line-height: 120%;
	}
}