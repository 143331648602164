@media (min-width:$screen-md-min){
	// Desktop - >= 990px

	.nav-bar-heading{
		display: none;
	}

	.nav-bar{
		width:100%;
		.nav-content > ul{
			text-align:right;
			& > li{
				display: inline-block;

				& + li:before{
					content:'•';
					display: inline-block;
					margin-left:15px;
					margin-right:15px;
				}
			}
		}
	}
}