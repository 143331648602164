.redes-sociais{
	font-size:18px;

	.fa{
		margin:0 4px;
		transition:all 0.6s linear;
		color:#FFFFFF;

		&:first-child{
			margin-left:0;
		}

		&:last-child{
			margin-right:0;
		}

		&:hover,
		&:focus{
			color:$bg-topo;
		}

	}

}