$imgs: '../imagens/estrutural';
$fonts: '../fonts';

$bg-topo: rgba(#F0971F, 1);
$bg-servicos: rgba(#EEF9FB, 1);
$bg-aplicacoes: #f0971f;
$bg-produtos: rgba(#121E3C, 1);
$bg-rodape: rgba(#0C1523, 1);
$bg-business: rgba(#E0EFF0, 1);
$bg-tv: rgba(#477685, 1);
$bg-smartphone: rgba(#EEF9FB, 1);
$bg-novidades: rgba(#273F58, 1);
$bg-header-internas: url(#{$imgs}/bg-header-internas.png) center top no-repeat;
$bg-case: url(#{$imgs}/bg-case.png) center top no-repeat;
$bg-case-content: rgba(#F0971F, 1);
$bg-sprite: url(#{$imgs}/sprite.png) no-repeat;