.internas-header{
	min-height: 308px;
	padding-top: 55px;
	margin-bottom: 80px;
	background: $bg-header-internas;

	h1{
		margin-top: 0;
		font-size: 42px;
		color: #FFF;
	}
}

.servico-default{
	h2{
		font-family: "open_sanslight";
		font-size: 30px;
		color: #2B2F3B;
		margin-bottom: 35px;
	}
	
	h3{
		font-family: "open_sanslight";
		color: #696E7B;

		@include resumo(20px, 1.8, 3);
		margin-bottom: 20px;
	}
	
	.text{
		font-family: "open_sansregular";
		color: #44474E;
		
		@include resumo(14px, 1.8, 4);
		margin-bottom: 35px;
	}
}


.servico{
	background-color:#477685;
	color:#FFFFFF;

	.servico-default{

		& > *{
			color:#FFFFFF;
		}

	}

}

.container-flex-center{
	min-height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;
	background: $bg-servicos;
}

.aplicacoes{
	background: $bg-aplicacoes;
}

.produtos{
	padding-top: 100px;
	padding-bottom: 150px;
	background: $bg-produtos;

	.produto-default{
		.item-default{
			.icon-default{
				min-height: 60px;
			}
			h2{
				font-family: "open_sanslight";
				font-size: 20px;
				color: #FFF;
				margin-top: 0;
				margin-bottom: 30px;
			}

			.text{
				font-family: "open_sanslight";
				color: #929FAA;
				line-height: 180%;
			}
		}
	}
}

.case{
	background: $bg-case;
	background-size: cover;
	min-height: 100vh;
	background-attachment: fixed;

	// align-items: flex-start !important;

	.block{
		background: $bg-case-content;
	}
	
	#carousel-cases{
		@media (min-width: $screen-sm-min){
			margin-left: -15px;
			margin-right: -15px;
		}
		position: relative;
		background: $bg-case-content;

		&:before{
			content: "";

			position: absolute;
			top: 0;
			right: 100%;

			height: 100%;
			width: 585px;

			background: $bg-case-content;
		}
	}

	.case-default{

		.item-default{
			h2, h3{
				line-height: 140%;
			}

			.text{
				line-height: 160%;
			}
		}
	}
}

.conteudo-flex{
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;

	.block{
		width: 100%;
		@media (min-width: $screen-md-min){
			max-width: 50%;
		}

		&.block-left{
			.content{
				@media (min-width: $screen-md-min){
					float: right;
				}
			}
		}

		.content{
			position: relative;
			@media (min-width: $screen-md-min){
				width: 585px;
			}

			h2, h3{
				line-height: 140%;
			}

			.text{
				line-height: 160%;
			}

			&.after-arrow{
				&:after{
					content: "";
					position: absolute;
					top: 50%;
					left: 100%;

					border-top: 12px solid transparent;
					border-right: 12px solid transparent;
					border-bottom: 12px solid transparent;
					border-left: 12px solid #FFF;

					transform: translateY(-50%);
				}
			}
		}
	}
}

.business{
	background: $bg-business;
}

.tv{
	background: $bg-tv;
}

.smartphone{
	background: $bg-smartphone;
}

.novidades{
	background: $bg-novidades;
}