@media (max-width:$screen-xs-max){
	// Celphones - < 768

	.nav-content{
		position:fixed;
		top:0;
		right:-300px;
		z-index:1000;
		width: 250px;
		height:100%;
		background:#FFF;
		box-shadow: 0 0 8px rgba(#000,0.8);
		overflow:auto;
		transition:right 1s linear;

		&:before{
			content:'';
			display: block;
			padding-bottom: (165 / 487) * 100%;
			background: url(#{$imgs}/logo.png) center center #F0971F no-repeat;
			background-size: 90% auto;
			border-bottom:20px solid transparent;
			border-top:20px solid transparent;
		}

		& > ul{
			text-align:left;

			& > li{
				display: block;
				border-bottom:1px solid #CCC;

				& > a{
					display: block;
					padding:10px 15px;
				}

				&:before{
					display: none;
				}
			}
		}
	}
}