@media (min-width: $screen-sm-min) and (max-width:$screen-sm-max){
	// Teblet -   > 768; < 992
	.nav-bar-heading{
		display: none;
	}

	.nav-bar{
		width:100%;
		.nav-content > ul{
			text-align:right;
			& > li{
				display: inline-block;

				& + li:before{
					content:'•';
					display: inline-block;
					margin-left:15px;
					margin-right:15px;
				}
			}
		}
	}

	.banners{
		margin-top:20px;
	}
}