.rodape{
	padding-top: 30px;
	padding-bottom: 10px;

	background: $bg-rodape;

	.form-pesquisa{
		max-width:400px;
		margin-left:auto;
		margin-right:auto;

		.input-pesquisa{
			display:flex;
		}
		
		input{
			border:1px solid transparent;
			border-radius: 30px 0 0 30px;
			padding: 10px 15px;
			background: #393C4C;
			color:#FFF;
			width: 100%;
			outline:none;

			&:focus{
				border-color: $brand-info;
				box-shadow: 0 0 8px $brand-info;
			}

			@include placeholdIt(){
				color:#FFF;
			}
		}

		.btn{
			border-top-left-radius:0;
			border-bottom-left-radius:0;
			@include button-variant(#FFF,#393C4C,#393C4C);
		}
	}

	h3{
		font-size: 22px;
		color: #AAAAAA;
	}

	.area-redes{
		margin-bottom:20px;
		color: #AAAAAA;
		font-weight:bold;

		.title{
			margin-right:10px;
		}

		& > *{
			display:inline-block;
		}

	}

	.contatos-rodape{
		margin-top: 30px;
		.contato{
			position: relative;
			padding-left: 65px;
			margin-bottom: 25px;

			.icon-default{
				position: absolute;
				left: 0;
				top: 50%;

				transform: translateY(-50%);

				background: #FFF;

				width: 28px;
				height: 28px;

				display: flex;
				justify-content: center;
				align-items: center;
			}

			span{
				font-family: "open_sanslight";
				font-size: 16px;
				color: #AAAAAA;
			}
		}
	}
}