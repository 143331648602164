.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
}

.owl-nav{
    display: none;
}

.btn{
    font-family: "open_sansregular";
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 25px;
}

.btn-purple{
    color: #FFF;
    background: #393C4C;

    &:hover{
        color: #FFF;
    }
}

.navbar-toggle{
    position: relative;
    z-index: 199;
    float: none;
    margin: 0;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 25px;
    display: inline-block;
    color: #FFF;
}

.btn-yellow{
    color: #FFF;
    background: #F0971F;

    &:hover{
        color: #FFF;
    }
}

.unlistenner{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.c-white{
    color: #FFF !important;
}

.c-gray{
    color: #656D7A !important;
}

.c-black{
    color: #30303F !important;
}

.c-yellow{
    color: #F0971F !important;
}

.bg-white{
    background: #FFF;
}

.pdl-15{
    padding-left: 15px;
}

.pdl-15{
    padding-left: 15px;
}

.pdl-30{
    padding-left: 30px;
}

.pdr-130{
    padding-right: 130px;
}

.pdr-50{
    padding-right: 50px;
}

.fz-18{
    font-size: 18px;
}

.fz-20{
    font-size: 20px;
}

.fz-22{
    font-size: 22px;
}

.fz-30{
    font-size: 30px;
}

.btn-novidade{
    display: inline-block;
    color: #FFF;
    background: #262329;
    padding: 5px 15px;

    position: absolute;
    bottom: 100%;
    left: 0;
}

.mw-165{
    min-width: 165px;
}

textarea{
    resize: none;
}

*[data-animate]{
    opacity:0;

    &.animated{
        opacity: 1;
    }
}