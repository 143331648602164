.ic-flexibilidade{
	@include make-icon(46px, 44px, 0 0);
}

.ic-controles{
	@include make-icon(45px, 45px, 0 -68px);
}

.ic-integracoes{
	@include make-icon(43px, 43px, 0 -138px);
}

.ic-open{
	@include make-icon(42px, 42px, 0 -205px);
}

.ic-download{
	@include make-icon(43px, 31px, 0 -272px);
}

.ic-pager-prev{
	@include make-icon(14px, 44px, 0 -327px);
}

.ic-pager-next{
	@include make-icon(14px, 44px, 0 -396px);
}