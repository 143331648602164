.wrapper{
	min-height:100vh;
	overflow-x:hidden;
}

html{
}

body{
	overflow-x:hidden;
	padding-right: 0 !important;
	font-family: "open_sansregular";
	font-size: 14px;
}

.lockscroll{
	overflow:hidden;
}

.container{
	max-width:11170px;
}

#modal-contato{
	display: flex !important;
	justify-content: center;
	align-items: center;

	height: 0;
	width: 0;
	
	&.in{
		width: 100%;
		height: 100%;
	}

	.modal-dialog{
		margin: 0;
		.modal-content{
			border-radius:  0;

			.modal-header{
				border-bottom: none;

				.modal-title{
					font-family: "open_sanslight";
					font-size: 30px;
					color: #2B2F3B;
				}
			}

			.modal-body{
				.form-control{
					border-radius: 0;
					min-height: 44px;
					border: 1px solid #AAA;

					font-family: "open_sanslight";
					font-size: 14px;
					color: #2B2F3B;

					@include placeholdIt(){
						font-family: "open_sanslight";
						font-size: 14px;
						color: #DDD;
					}

					&:focus{
						outline: none;
					}
				}
			}
		}
	}
}