.section__title{
    margin-bottom: 20px;
    font-weight: 900;
}

.manual__download{
    width: 100%;
    max-width: 300px;
    
}

.manual__foto{
    position: relative;
    padding-bottom: percentage(400/300);
    border-radius: 10px;
    overflow: hidden;
    border:1px solid #CCC;
    margin-bottom: 10px;

    img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.manual__name{
    text-align: center;
    font-weight: 700;
}

.video__aula{
    .embed-responsive{
        background: #000;
        margin-bottom: 10px;
        padding-bottom: percentage(9/16);
    }

    .video__title{
        font-weight: 900;
    }
}