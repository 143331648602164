.topo{
	padding-bottom: 30px;

	.bar-topo{
		background-color:#0D0723;
		color:#FFFFFF;
		padding:2px 0;
		text-align:right;
		margin-bottom:45px;
	}

	background: $bg-topo;

	.form-pesquisa{
		@media (max-width: $screen-xs-max){
			margin-top: 15px;
		}
		.input-pesquisa{
			position: relative;

			input,
			button{
				border: none;
				background: transparent;
				min-height: 44px;

				color: #A8ABAC;

				&:focus{
					outline: none;
				}
			}

			button{
				width: 40px;
			}

			input{
				min-width: calc(100% - 50px);
				font-family: "open_sansregular";
				font-size: 14px;
				padding-left: 25px;

				@include placeholdIt(){
					font-family: "open_sansregular";
					font-size: 14px;
					color: #A8ABAC;
				}
			}

			border-radius: 25px;
			background: #393C4C;
		}
	}

	.inline-middle{
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media (max-width: $screen-xs-max){
			flex-wrap: wrap;
			margin-top: 20px;
		}
	}

	.navbar-toggle{
		@media (max-width: $screen-xs-max){
			width: 100%;
			text-align: right;
		}
	}

	.navbar-nav{
		@media (min-width: $screen-sm-min){
			position: absolute;
			right: 0;
			top: 0;
			z-index: 49;
			
			width: 0;
			overflow: hidden;
			border-radius: 25px;

			&.in{
				min-width: 265px;
			}
		}

		@media (max-width: $screen-xs-max){
			float: none;
			width: 100%;
			margin: 0;
			padding: 0;
		}

    	background: rgba(#393C4C, .8);

		#menu{
			padding-top: 50px;
	    	
	    	> li{
	    		position: relative;
	    		padding-left: 10px;

	    		> a{
		    		&:before{
		    			content: "\f04b";
		    			font-family: "FontAwesome";
		    			font-size: 12px;
		    			color: #FFF;
		    			margin-right: 12px;

		    		}

	    			display: block;
	    			font-family: "open_sansregular";
	    			font-size: 14px;
	    			color: #FFF;
	    			margin-bottom: 30px;
	    		}

	    		&.open{
	    			a{
	    				margin-bottom: 0;
	    			}
	    		}

	    		&:hover,
	    		&.active,
	    		&.open{
	    			> a{
		    			&:before{
		    				color: #FFC926;
		    			}

	    				color: #FFC926;
	    				text-decoration: none;
	    				background: transparent;
	    			}
	    		}

	    		.dropdown-menu{
	    			position: relative;
	    			float: none;
	    			box-shadow: none;
	    			border: none;
	    			padding: 0;
	    			padding-left: 30px;
	    			margin: 0;
	    			padding-top: 10px;
	    			padding-bottom: 30px;
	    			background: transparent;

	    			li{
	    				a{
			    			display: block;
			    			font-family: "open_sansregular";
			    			font-size: 14px;
			    			color: #FFF;
	    					padding: 5px 0;

		    				&:before{
		    					display: none;
		    				}
		    				
		    				&:hover{
		    					text-decoration: none;
	    						color: #FFC926;
		    				}
	    				}
	    			}
	    		}
	    	}
    	}
	}

	.banners{
		position: relative;

		.pager{
			position: absolute;
			top: 50%;

			transform: translateY(-50%);
			z-index: 39;

			&.pager-prev{
				left: calc(50% - 600px)
			}
			&.pager-next{
				right: calc(50% - 600px)
			}
		}
	}
}

.nav-bar{

	width:100%;
	
	.nav-bar-heading{
		text-align:center;
		@media (max-width: $screen-xs-max){
			padding:15px;
			margin-bottom:20px;
		}
	}

	ul{
		list-style:none;
		padding-left:0;
		margin-bottom:0;
		font-weight: bold;
	}

	&.open{
		.toggle-nav:before{
			content:'';
			display: block;
			width:100%;
			height:100%;
			left:0;
			top:0;
			z-index:999;
			background: rgba(#000,0.8);
			position:fixed;
			cursor:default;
		}

		.nav-content{
			position:fixed;
			top:0;
			right:0;
			z-index:1000;
			width: 250px;
			height:100%;
			background:#FFF;
			box-shadow: 0 0 8px rgba(#000,0.8);
			overflow:auto;

			&:before{
				content:'';
				display: block;
				padding-bottom: (165 / 487) * 100%;
				background: url(#{$imgs}/logo.png) center center #F0971F no-repeat;
				background-size: 90% auto;
				border-bottom:20px solid transparent;
				border-top:20px solid transparent;
			}

			& > ul{
				text-align:left;

				& > li{
					display: block;
					border-bottom:1px solid #CCC;

					& > a{
						display: block;
						padding:10px 15px;
					}

					&:before{
						display: none;
					}
				}

				.dropdown-menu{
					position:relative;
					float:none;
					width:100%;
					border-radius:0;
					box-shadow:none;
				}
			}
		}
	}

	.nav-content{
		& > ul{
			& > li{
				&.active > a,
				&.open > a,
				&:hover > a{
					color: $brand-primary;
					text-decoration:none;
				}
			}
		}
	}
}

.toggle-nav{
	@include button-variant(#FFF, #27373F, #27373F);
	outline: none !important;
}

.drop-area-restrita{
	min-width: 200px;
}

.drop-area-restrita form{
	padding: 15px;

	& + a{
		border-top: 1px solid #CCC;
	}
}

.drop-area-restrita-link{
	display: block;
	padding: 10px 15px;
	transition: all .3s linear;

	display: grid;
	grid-template-columns: min-content 1fr;

	& ~ a{
		border-top: 1px solid #CCC;
	}

	.fa{
		margin-right: 10px;
		line-height: 1.5;
	}
	
	&:hover{
		background: #EEE;
	}
}